'use strict';

module.exports = {
    initEvents: function () {
        // Set instant flow names
        if (window.localStorage.getItem('flow') !== 'quantum') {
            window.localStorage.setItem('flow', 'quantum');
            window.dispatchEvent(new Event('Flow Name Set'));
        } else {
            window.dispatchEvent(new Event('Flow Name Set'));
        }

        $(document).on('click', '.pd-generic-button .qf-btn, .pd-check-availability_compact .qf-btn', function (e) {
            e.preventDefault();
            let currentCtlCustomer = $('#centurylink-existing-yes').prop('checked') ? 'yes' : 'no';
            $(this).trigger('addresscheck:action', { btn: 'check availability', currentCtlCustomer });

            if ($(this).attr('href') && $(this).attr('href').length > 0) {
                var target = $(this).attr('target') ? $(this).attr('target') : '_self';
                window.open($(this).attr('href'), target);
            }
        });
    }
};
